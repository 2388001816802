@font-face {
  font-family: "Barlow";
  src: local("Barlow"),
    url(./Utils/sd-styles/Barlow/Barlow-Regular.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  color: #181818;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.rc-anchor-invisibler {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}
.sd-imp {
  color: red;
  padding-left: 3px;
}
