.sd-donation-one {
  font-family: "Barlow", sans-serif;
  -webkit-overflow-scrolling: touch;
  .sd-donation-top {
    padding: 60px;

    .sd-donation-inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fef1e7;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      p {
        text-align: center;
        font-size: 30px;
        width: 65%;
        padding: 60px;
        color: #181818;
      }
      .inner-image {
        position: absolute;
        height: 92%;
        width: 98%;
      }
    }
  }
  h3 {
    padding: 0px 60px 60px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #181818;
    text-align: center;
  }
  .sd-donation-bottom {
    -webkit-overflow-scrolling: touch;

    .sd-donation-bottom-top {
      -webkit-overflow-scrolling: touch;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 30px;
        color: #2f3193;
        padding-bottom: 10px;
        letter-spacing: 0;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 10px;
      }
      padding: 0px 40px 30px 30px;
    }
    .sd-donation-bottom-bottom {
      padding-left: 50px;
      padding-right: 50px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .sd-donation-div {
        // overflow: hidden;
        padding: 18px 18px 0;
        margin-bottom: 30px;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        width: 380px;
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px;
        .sd-head-image {
          //   height: 150px;
          width: 60px;
          height: 60px;
          margin: 15px 15px 0px 15px;

          align-self: center;
        }
        .spcl {
          width: 80px;
          height: 50px;
          margin: 25px 25px 0px 25px;
        }
        h4 {
          text-align: center;

          color: #2f3193;
          font-weight: 600;
          font-size: 20px;
          line-height: 16px;
          padding: 10px 0 0px;
        }
        h5 {
          text-align: center;
          letter-spacing: 0.3px;
          font-size: 16px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4; /* number of lines to show */
          -webkit-box-orient: vertical;
          line-height: 24px;
        }
        h6 {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 0px 0px 10px;
          font-size: 16px;
          font-weight: 600;
          color: #f58220;
          margin-bottom: 0px;
          cursor: pointer;

          img {
            height: 11px;
            margin-left: 3px;
            margin-top: 2px;
          }
        }

        .sd-div-bottom {
          display: flex;
          padding: 12px 0;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #bfbfbf;

          .left {
            img {
              height: 24px;
              width: 33px;
              padding: 4px;
              cursor: pointer;
            }
            .border-l {
              border-left: 1px solid grey;
              padding-left: 10px;
            }
            .padding-r {
              padding-right: 10px;
            }
          }
          button {
            color: white;
            background-color: #f58220;
            border: none;
            display: flex;
            align-self: center;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            font-size: 14px;
            padding: 6px 13px;
            font-weight: 600;
            letter-spacing: 0.3px;
            white-space: nowrap;

            .imgs {
              height: 11px;
              margin-left: 6px;
              margin-top: 2.5px;
            }
          }
        }
      }
    }
  }
  .sd-copy {
    position: absolute;
    top: 20px;
    font-size: 18px;
    right: 47%;
    font-weight: 500;
    background-color: rgb(228, 252, 253);
    padding: 5px 15px;
    border-radius: 4px;
    animation: slow-message 0.5s;
    border: 1.5px solid #bfbfbf;
  }
}

.sd-donation-annaprasadam {
  .sd-donation-annaprasadam-top {
    padding: 60px;

    .sd-donation-annaprasadam-inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fef1e7;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      p {
        text-align: center;
        font-size: 26px;
        width: 65%;
        padding: 45px 15px 45px 15px;
        color: #181818;
      }
      .sd-annaprasadam-inner-image {
        position: absolute;
        height: 90%;
        width: 98%;
      }
    }
  }
  .sd-donation-annaprasadam-bottom {
    padding: 0px 60px 60px;

    h3 {
      line-height: 24px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: #181818;
      text-align: center;
    }
    .sd-donation-annaprasadam-div-bottom {
      border-top: 1px solid #e9e6e6;
      border-bottom: 1px solid #e9e6e6;
      margin-top: 30px;
      display: flex;
      padding: 12px 0px 12px 0;
      justify-content: space-between;
      align-items: center;
      .sd-a-left {
        img {
          height: 24px;
          width: 33px;

          padding: 4px;
        }
        .border-l {
          border-left: 1px solid #e9e6e6;
          padding-left: 10px;
        }
        .padding-r {
          padding-right: 10px;
        }
      }
      button {
        color: white;
        background-color: #f58220;
        border: none;
        display: flex;
        white-space: nowrap;

        align-self: center;
        align-items: center;

        justify-content: center;
        font-size: 14px;
        padding: 6px 13px;
        font-weight: 600;
        letter-spacing: 0.3px;
        .imgs {
          height: 11px;
          margin-left: 6px;
          margin-top: 2px;
        }
      }
    }
  }
  .sd-copy {
    position: absolute;
    top: 20px;
    font-size: 18px;
    right: 47%;
    font-weight: 500;
    background-color: rgb(228, 252, 253);
    padding: 5px 15px;
    border-radius: 4px;
    animation: slow-message 0.5s;
    border: 1.5px solid #bfbfbf;
  }
}
@keyframes slow-message {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 0%;
  }
}
@media only screen and (min-width: 1600px) {
  .sd-donation-annaprasadam {
    .sd-donation-annaprasadam-top {
      .sd-donation-annaprasadam-inner {
        p {
          font-size: 30px;
          padding: 60px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-donation-one {
    .sd-donation-top {
      .sd-donation-inner {
        p {
          padding: 20px;
          font-size: 20px;
        }
        h3 {
          font-size: 20px;
        }
      }
    }
    h3 {
      font-size: 20px;
    }
    .sd-donation-bottom {
    }
  }
  .sd-donation-annaprasadam {
    .sd-donation-annaprasadam-top {
      .sd-donation-annaprasadam-inner {
        p {
          padding: 45px 10px 45px 10px;
          font-size: 20px;
        }
      }
    }
    .sd-donation-annaprasadam-bottom {
      h3 {
      }
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 600px) {
  .sd-donation-one {
    .sd-donation-top {
      padding: 30px;

      .sd-donation-inner {
        p {
          font-size: 14px;
          padding: 30px;
        }
      }
    }
  }
  .sd-donation-annaprasadam {
    .sd-donation-annaprasadam-top {
      padding-bottom: 30px;
      .sd-donation-annaprasadam-inner {
        p {
          padding: 30px 15px 30px 15px;
          font-size: 18px;
        }
        .sd-annaprasadam-inner-image {
          height: 93%;
        }
      }
    }
    .sd-donation-annaprasadam-bottom {
      h3 {
        font-size: 17px;
      }
    }
  }
}
@media only screen and (min-width: 380px) and (max-width: 600px) {
  .sd-donation-one {
    .sd-donation-top {
      padding: 30px;
      padding-top: 30px;

      .sd-donation-inner {
        p {
          font-size: 14px;
          padding: 20px;
        }
        .inner-image {
          width: 95%;
          display: none;
        }
      }
    }
    h3 {
      font-size: 16px;
      padding: 0px 30px 30px;
    }
    .sd-donation-bottom {
      .sd-donation-bottom-bottom {
        .sd-donation-div {
          .sd-div-bottom {
            button {
              font-size: 14px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .sd-donation-annaprasadam {
    .sd-donation-annaprasadam-top {
      padding: 30px 20px 0px 20px;
      .sd-donation-annaprasadam-inner {
        p {
          font-size: 14px;
          padding: 10px;
          margin-bottom: 0px;
        }
        .sd-annaprasadam-inner-image {
          width: 95%;
          display: none;
        }
      }
    }
    .sd-donation-annaprasadam-bottom {
      padding: 20px;

      h3 {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 380px) {
  .sd-donation-one {
    .sd-donation-top {
      padding: 30px 30px 30px 30px;
      padding-top: 30px;

      .sd-donation-inner {
        p {
          font-size: 14px;
          padding: 20px;
        }
        .inner-image {
          width: 95%;
          display: none;
        }
      }
    }
    h3 {
      font-size: 16px;
      padding: 0px 30px 30px;
    }
    .sd-donation-bottom {
      .sd-donation-bottom-top {
        padding-bottom: 0px;
      }
      .sd-donation-bottom-bottom {
        padding: 20px;
        .sd-donation-div {
          h5 {
            font-size: 15px;
          }
        }
      }
    }
  }
  .sd-donation-annaprasadam {
    .sd-donation-annaprasadam-top {
      padding: 30px 20px 0px 20px;
      .sd-donation-annaprasadam-inner {
        p {
          font-size: 14px;
          padding: 10px;
          margin-bottom: 0px;
        }
        .sd-annaprasadam-inner-image {
          width: 95%;
          display: none;
        }
      }
    }
    .sd-donation-annaprasadam-bottom {
      padding: 20px;

      h3 {
        font-size: 14px;
      }
    }
  }
}
