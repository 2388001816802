.w-100 {
  width: 100%;
  font-family: "Barlow", sans-serif;
}
.under {
  text-decoration: underline !important;
  color: #2f3193;
}
.sd-template-one {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #efefef;
  font-family: "Barlow", sans-serif;

  .sd-t1-slider {
    // padding: 33px 33px 20px;
    padding: 20px 20px 30px 20px;
    overflow: hidden;
    .slick-slider {
      .slick-prev {
        left: 3.5vw;
        z-index: 10;
      }
      .slick-next {
        right: 3.5vw;
        z-index: 10;
      }
      .slick-dots {
        bottom: -20px;
        z-index: 2;
        li {
          margin: 0 1px;
          color: #2f3193;
          button {
            opacity: 1;
            &::before {
              color: #2f3193;
              font-size: 10px;

              opacity: 1;
            }
          }
        }
        .slick-active {
          button {
            white-space: nowrap;

            &::before {
              color: #f58220 !important;
            }
          }
        }
      }
      .slick-list {
        .slick-track {
          div {
            border: none;
            box-shadow: none;
            outline: none;
            img {
              border: none;
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
    // .owl-dots {
    //   border: none;
    //   .owl-dot {
    //     border: none;
    //     box-shadow: none;
    //     outline: none;
    //     span {
    //       background: #2f3193 !important;
    //     }
    //     button {
    //       outline: none;
    //       border: none;
    //       box-shadow: none;
    //       color: #2f3193;
    //     }
    //   }
    // }
    // .sd-fade-in {
    //   animation: fade-in 1s;
    // }
  }
  .sd-fade-in {
    animation: fade-in 1s;
  }
  .sd-t1-bottom {
    height: 55px;
    overflow: hidden;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: 1px solid #d0d0d0;
    border-top: 1px solid #d0d0d0;
    align-items: center;
    justify-content: space-between;
    .sd-orange {
      width: 9%;
      margin: 0px;
      color: #f58220;
      font-size: 16px;
      border-right: 1px solid #d8d8d8;
      text-align-last: center;
      font-weight: 600;
      padding-right: 10px;
    }

    .sd-marquee-text {
      font-size: 15px;
      color: #2f3193;
      width: 80%;
      font-weight: 500;
      cursor: pointer;
    }

    div {
      align-items: center;
      text-align: -webkit-center;
      button {
        padding: 6px 13px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        background-color: #f58220;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        border-radius: 3px;

        img {
          margin-left: 8px;
          height: 11px;
        }
      }
    }
  }
}
.sd-template-two {
  height: 500px;
  padding: 60px;
  overflow: hidden;
  display: flex;
  font-family: "Barlow", sans-serif;

  .sd-box-one {
    padding: 35px;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .sd-box-one-content {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      h2 {
        color: #2f3193;
        font-weight: 600;
        font-size: 20px;
        margin: 0px;
      }
      p {
        line-height: 19px;
        font-size: 15px;
        color: #181818;
        text-align: center;
      }
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        padding: 5px 0 10px;
        font-size: 15px;
        font-weight: 600;
        color: #2f3193;
        margin-bottom: 0px;
        cursor: pointer;

        img {
          height: 11px;
          margin-left: 5px;
          // margin-top: 2px;
          // filter: invert(1);
        }
      }
      .c-white {
        color: white;
      }
      .c-18 {
        color: #181818;
      }
      button {
        border: none;
        font-size: 14px;
        padding: 6px 13px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        align-content: center;
        outline: none;
        white-space: nowrap;
        border-radius: 3px;
        img {
          height: 11px;
          margin-left: 8px;
        }
      }
      .sd-c-white {
        color: white;
      }
      .sd-c-voilet {
        color: #2f3293;
      }
      .sd-c-orange {
        color: #f58220;
      }
      .sd-b-white {
        background-color: white;
      }
      .sd-b-orange {
        background-color: #f58220;
      }
    }

    .sd-box-one-image {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .sd-logo-img {
        height: 65px;
        margin-bottom: 16px;
      }
      .sd-size-one {
        height: 58px;
      }
      .sd-size-two {
        height: 45px;
      }
      .sd-size-three {
        height: 52px;
      }
      .sd-size-four {
        height: 55px;
      }
    }
  }
  .sd-first-color {
    background-color: #fde6d2;
  }
  .sd-second-color {
    background-color: #facda6;
  }
  .sd-third-color {
    background-color: #f58220;
  }
  .sd-fourth-color {
    background-color: #2f3293;
  }
}
.sd-template-three {
  font-family: "Barlow", sans-serif;

  .sd-t3-inner-div {
    height: 100%;
    width: 100%;
    background-image: url("../../Assets/sd-home-pngs/SD-srisaila-tv-bg.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: flex;
    justify-content: space-around;
    .sd-t3-box-left {
      width: 50%;
      padding: 60px 60px;
      display: flex;
      flex-direction: column;
      .sd-t3-box-left-one {
        display: flex;
        align-items: center;
        img {
          height: 70px;
          width: 70px;
        }
        h2 {
          color: white;
          margin-left: 23px;
          font-size: 34px;
          font-weight: 400;
        }
        margin-bottom: 30px;
      }
      .sd-t3-box-left-two {
        p {
          color: white;
          word-wrap: break-word;
          font-size: 20px;
          line-height: 25px;
          font-weight: 400;
        }
      }
      .sd-t3-box-left-three {
        word-wrap: break-word;
        p {
          color: white;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          opacity: 0.8;
        }
        margin-bottom: 10px;
      }
      .sd-t3-box-left-four {
        button {
          border: none;
          background-color: #f58220;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          padding: 6px 13px;
          letter-spacing: 0.2px;
          font-weight: 600;
          white-space: nowrap;
          border-radius: 3px;
          img {
            height: 14px;
            margin-left: 8px;
          }
        }
      }
    }
    .sd-t3-box-right {
      width: 50%;
      // height: 100%;
      padding: 60px 60px 60px 0;
      display: flex;
      justify-content: center;
      Iframe {
        // height: 400px;
        width: 100%;
        border: 5px solid black;
      }
      img {
        height: 400px;
        width: 550px;
      }
    }
  }
}
.sd-template-four {
  height: 640px;
  padding: 60px 60px 50px 60px;
  display: flex;
  font-family: "Barlow", sans-serif;

  .sd-t4-box-left {
    width: 50%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // background-color: #b3a7a3;
    background-image: url("../../Assets/sd-home-pngs/SD-about-srisailam-bg.png");
    background-repeat: no-repeat;
    background-size: cover; /* Resize the background image to cover the entire container */
    .sd-t4-box-left-one {
      h4 {
        color: #2f3293;
        margin-bottom: -10px;
        font-size: 26px;
        font-weight: 600;
      }
      p {
        font-size: 38px;
        letter-spacing: 0px;
        color: #2f3193;
      }
    }
    .sd-t4-box-left-two {
      p {
        word-wrap: break-word;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #181818;
        margin-bottom: 30px;
        span {
          font-weight: 400;
        }
      }
      span {
        font-weight: 400;
      }
    }
    .sd-t4-box-left-three {
      word-wrap: break-word;
      p {
        word-wrap: break-word;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
      }
      margin-bottom: 10px;
    }
    .sd-t4-box-left-four {
      button {
        border: none;
        background-color: #f58220;
        color: white;
        display: flex;
        padding: 6px 13px;
        font-size: 14px;
        font-weight: 600;
        justify-content: space-evenly;
        align-items: center;
        white-space: nowrap;
        border-radius: 3px;
        img {
          height: 14px;
          margin-left: 8px;
        }
      }
    }
  }
  .sd-t4-box-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .sd-t4-box-right-one {
      height: 50%;
      width: 50%;
      background-color: #f58220;
      display: flex;
      color: white;
      justify-content: space-evenly;
      padding: 30px;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        color: white;
        margin-bottom: 0px;
      }
      button {
        font-size: 14px;
        font-weight: 600;
        padding: 6px 13px;
        border: none;
        background-color: #ffff;
        color: #f58220;
        display: flex;
        white-space: nowrap;
        border-radius: 3px;
        justify-content: space-evenly;
        align-items: center;
        img {
          height: 13px;
          margin-left: 7px;
        }
      }
      img {
        height: 68px;
      }
    }
    .sd-t4-box-right-two {
      height: 50%;
      width: 50%;
      overflow: hidden;
      background-image: url("../../Assets/sd-kum.jpg");

      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    }
    .sd-t4-box-right-three {
      height: 50%;
      width: 50%;
      background-color: #2f3293;
      overflow: hidden;
      background-image: url("../../Assets/sd-home-pngs/SD-main-offerings-img.png");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    }
    .sd-t4-box-right-four {
      height: 50%;
      width: 50%;
      background-color: #2f3293;
      display: flex;
      color: white;
      justify-content: space-evenly;
      padding: 2vw;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: white;
        margin-bottom: 0px;
      }
      button {
        font-size: 14px;
        font-weight: 600;
        padding: 6px 13px;
        border: none;
        background-color: #ffff;
        color: #2f3193;
        display: flex;
        white-space: nowrap;
        border-radius: 3px;
        justify-content: space-evenly;
        align-items: center;
        img {
          height: 13px;
          margin-left: 6px;
        }
      }
      img {
        height: 60px;
      }
    }
  }
}
.sd-template-five {
  padding: 0 60px 30px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  font-family: "Barlow", sans-serif;

  h2 {
    align-self: center;
    margin-bottom: 5px;
    color: #2f3193;
    font-size: 36px;
    font-weight: 400;
  }
  .sd-photo-grid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    div {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    img {
      height: 87%;
      width: 23.5%;
      border-radius: 3px;
    }
  }
  .sd-photo-grid-full {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    place-content: center;
    div {
      width: 22.5%;
      margin: 13px;
      .__react_modal_image__modal_container {
        width: 100vw;
        margin: 0px;
        .__react_modal_image__header {
          background-color: transparent;
          width: 98%;
          .__react_modal_image__caption {
            display: none;
          }
          // display: none;
        }
        .__react_modal_image__modal_content {
          width: 100vw;
          div {
            img {
              width: 70%;
              border-radius: 3px;
            }
          }
        }
      }
      img {
        border-radius: 3px;
      }
    }
  }
  button {
    border: none;
    background-color: #f68121;
    color: white;
    font-size: 14px;
    padding: 6px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    align-self: center;
    white-space: nowrap;
    border-radius: 3px;
    img {
      height: 11px;
      margin-left: 8px;
    }
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1450px) {
  .sd-template-one {
    .sd-t1-slider {
      .slick-slider {
        .slick-dots {
          bottom: -20px;
          li {
            margin: 0 1px;
            color: #2f3193;
            button {
              opacity: 1;
              &::before {
                color: #2f3193;
                font-size: 10px;

                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #f58220;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      .sd-orange {
        font-size: 18px;
      }
    }
  }
  .sd-template-two {
    height: 450px;
  }
  .sd-template-three {
    .sd-t3-inner-div {
      .sd-t3-box-right {
        text-align-last: end;
        Iframe {
          // height: 400px;
          // width: 650px;
        }
        img {
          height: 400px;
          width: 650px;
        }
      }
    }
  }
  .sd-template-five {
    .sd-photo-grid {
      div {
        margin-bottom: 3.5vh;
      }
      img {
        width: 23.5%;

        // margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-template-one {
    .sd-t1-slider {
      .slick-slider {
        .slick-dots {
          bottom: -20px;
        }
      }
    }
    .sd-t1-bottom {
      .sd-orange {
        width: 12%;
        font-size: 14px;
      }
      .sd-marquee-text {
        width: 75%;
        font-size: 15px;
      }
      div {
        button {
          font-size: 14px;
          padding: 6px 13px;
          img {
            height: 11px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 400px;
    .sd-box-one {
      padding: 28px;
      .sd-box-one-content {
        p {
          // margin-bottom: 0px;
          font-size: 11px;
        }
        button {
          font-size: 12px;
          padding: 6px 13px;
          img {
            height: 8px;
            margin-left: 7px;
          }
        }
        h2 {
          font-size: 17px;
        }
      }
      .sd-box-one-image {
        .sd-logo-img {
          height: 30px;
        }
      }
    }
  }
  .sd-template-three {
    height: 450px;
    .sd-t3-inner-div {
      .sd-t3-box-left {
        .sd-t3-box-left-one {
          img {
            height: 50px;
            width: 50px;
          }
          h2 {
            margin-left: 15px;
            font-size: 29px;
          }
          margin-bottom: 25px;
        }
        .sd-t3-box-left-two {
          p {
            font-size: 16px;
          }
        }
        .sd-t3-box-left-three {
          p {
            font-size: 13px;
            opacity: 0.8;
          }
          margin-bottom: 10px;
        }
        .sd-t3-box-left-four {
          button {
            font-size: 12px;
            padding: 6px 13px;
            img {
              height: 10px;
              margin-left: 7px;
            }
          }
        }
      }
      .sd-t3-box-right {
        Iframe {
          // height: 300px;
          // width: 420px;
        }
        img {
          height: 300px;
          width: 420px;
        }
      }
    }
  }
  .sd-template-four {
    height: 520px;
    .sd-t4-box-left {
      padding: 40px;
      .sd-t4-box-left-one {
        h4 {
          font-size: 15px;
        }
        p {
          margin-bottom: 5px;
          font-size: 26px;
        }
      }
      .sd-t4-box-left-three {
        p {
          font-size: 14px;
        }
      }
      .sd-t4-box-left-two {
        p {
          word-wrap: break-word;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.3;
          margin-bottom: 20px;
          span {
            font-size: 14px !important;
          }
        }
      }
      .sd-t4-box-left-four {
        button {
          font-size: 12px;
          padding: 6px 13px;
          img {
            height: 10px;
            margin-left: 7px;
          }
        }
      }
    }
    .sd-t4-box-right {
      .sd-t4-box-right-one {
        img {
          height: 50px;
        }
        button {
          font-size: 12px;
          padding: 6px 13px;
          img {
            height: 10px;
            margin-left: 7px;
          }
        }
        h3 {
          font-size: 18px;
        }
      }
      .sd-t4-box-right-two {
      }
      .sd-t4-box-right-three {
      }
      .sd-t4-box-right-four {
        img {
          height: 40px;
        }
        button {
          font-size: 12px;
          padding: 6px 13px;
          img {
            height: 10px;
            margin-left: 7px;
          }
        }
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .sd-template-five {
    button {
      font-size: 12px;
      padding: 6px 13px;
      margin-top: 10px;
      img {
        margin: 2px;

        height: 8px;
        margin-left: 7px;
      }
    }
    .sd-photo-grid {
      height: 90%;
      div {
        // flex-wrap: wrap;
        margin-bottom: 0.6vh;
        img {
          margin: 5px;

          // height: 42%;
          // width: 46%;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 650px) {
  .sd-template-one {
    .sd-t1-slider {
      padding: 15px;
      .slick-slider {
        .slick-dots {
          bottom: -13px;
          li {
            margin: 0 1px;
            color: #2f3193;
            width: 15px;
            height: 15px;
            button {
              font-size: 5px;
              opacity: 1;
              &::before {
                font-size: 8px;

                color: #2f3193;
                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #f58220;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      height: 40px;
      padding-left: 30px;
      padding-right: 30px;
      .sd-orange {
        width: 12%;
        font-size: 10px;
      }
      .sd-marquee-text {
        width: 70%;
        font-size: 11px;
      }
      div {
        button {
          padding: 1px 8px;

          font-size: 10px;
          img {
            height: 8px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 280px;
    padding: 30px;
    .sd-box-one {
      padding: 18px;
      .sd-box-one-content {
        p {
          margin-bottom: 5px;
          font-size: 10px;
          line-height: 1.3;
        }
        button {
          font-size: 10px;
          padding: 1px 8px;
          img {
            height: 8px;
            margin-left: 5px;
          }
        }
        h2 {
          font-size: 13px;
        }
      }
      .sd-box-one-image {
        .sd-logo-img {
          height: 30px;
        }
      }
    }
  }
  .sd-template-three {
    height: 330px;
    .sd-t3-inner-div {
      .sd-t3-box-left {
        padding: 49px 30px 51px 30px;

        .sd-t3-box-left-one {
          img {
            height: 40px;
            width: 40px;
          }
          h2 {
            margin-left: 8px;
            font-size: 18px;
          }
          margin-bottom: 10px;
        }
        .sd-t3-box-left-two {
          p {
            font-size: 13px;
            line-height: 1.5;
            margin-bottom: 5px;
          }
        }
        .sd-t3-box-left-three {
          p {
            font-size: 11px;
            opacity: 0.8;
            margin-bottom: 10px;
          }
          margin-bottom: 2px;
        }
        .sd-t3-box-left-four {
          button {
            font-size: 10px;
            border: none;
            padding: 1px 8px;
            img {
              height: 8px;
              margin-left: 5px;
            }
          }
        }
      }
      .sd-t3-box-right {
        padding: 49px 33px 51px 0px;
        Iframe {
          // height: 230px;
          // width: 280px;
        }
        img {
          height: 230px;
          width: 280px;
        }
      }
    }
  }
  .sd-template-four {
    height: 450px;
    padding: 30px;

    .sd-t4-box-left {
      padding: 30px;
      .sd-t4-box-left-one {
        h4 {
          font-size: 15px;
          margin-bottom: -5px;
        }
        p {
          font-size: 20px;
          letter-spacing: 1px;
          color: #2f3293;
          margin-bottom: 5px;
        }
      }
      .sd-t4-box-left-three {
        p {
          font-size: 11px;
          line-height: 1.5;
        }
      }
      .sd-t4-box-left-two {
        p {
          font-size: 14px;
          line-height: 1.3;
          margin-bottom: 15px;
          span {
            font-size: 13px !important;
          }
        }
      }
      .sd-t4-box-left-four {
        button {
          font-size: 10px;
          border: none;
          padding: 1px 8px;

          img {
            height: 8px;
            margin-left: 5px;
          }
        }
      }
    }
    .sd-t4-box-right {
      .sd-t4-box-right-one {
        padding: 20px;
        img {
          height: 45px;
        }
        button {
          font-size: 10px;
          border: none;
          padding: 1px 8px;
          img {
            height: 8px;
            margin-left: 5px;
          }
        }
        h3 {
          font-size: 16px;
        }
      }
      .sd-t4-box-right-two {
      }
      .sd-t4-box-right-three {
      }
      .sd-t4-box-right-four {
        padding: 20px;
        img {
          height: 37px;
        }
        button {
          font-size: 10px;
          border: none;
          padding: 1px 8px;
          img {
            height: 8px;
            margin-left: 3px;
          }
        }
        h3 {
          font-size: 16px;
        }
      }
    }
  }
  .sd-template-five {
    .sd-photo-grid {
      div {
        margin-bottom: 10px;
      }
    }
    .sd-photo-grid-full {
      div {
        width: 45%;
      }
    }

    button {
      font-size: 10px;
      border: none;
      padding: 1px 8px;
      margin-top: 10px;
      img {
        height: 8px;
        margin-left: 5px;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .sd-template-one {
    .sd-t1-slider {
      padding: 10px;
      padding-bottom: 20px;
      // padding: 6px 2px 12px 5px;
      .slick-slider {
        .slick-dots {
          bottom: -18px;
          li {
            margin: 0 0px;
            width: 15px;
            color: #2f3193;
            button {
              opacity: 1;
              &::before {
                color: #2f3193;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
          .slick-active {
            button {
              opacity: 1;
              &::before {
                color: #f58220;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      .sd-orange {
        width: 20%;
        font-size: 11px;
        padding-right: 3px;
        text-align: center;
      }
      .sd-marquee-text {
        width: 55%;
        font-size: 12px;
      }
      div {
        width: 25%;
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 9px;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 1000px;
    padding: 30px;
    flex-wrap: wrap;
    justify-content: space-around;
    .sd-box-one {
      width: 100%;
      padding: 10px;
      display: flex;
      .sd-box-one-content {
        padding-left: 25px;
        padding-right: 25px;

        p {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 15px;
        }
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 10px;
            margin-left: 7px;
          }
        }
        h2 {
          font-size: 16px;
          margin: 0px;
        }
        // border-left: 0.5px solid #dbdbdb;
      }
      .sd-box-one-image {
        height: 30%;
        .sd-logo-img {
          height: 50px;
          margin: 0px;
        }
      }
    }
  }
  .sd-template-three {
    height: 485px;
    .sd-t3-inner-div {
      flex-direction: column;

      .sd-t3-box-left {
        padding: 20px 30px 15px 30px;
        width: 100%;
        height: 55%;

        .sd-t3-box-left-one {
          img {
            height: 45px;
            width: 45px;
          }
          h2 {
            margin-left: 8px;
            font-size: 18px;
          }
          margin-bottom: 10px;
        }
        .sd-t3-box-left-two {
          p {
            font-size: 14px;
            line-height: 1.3;
            margin-bottom: 7px;
          }
          // margin-bottom: 7px;
        }
        .sd-t3-box-left-three {
          p {
            font-size: 12px;
            opacity: 1;
            margin-bottom: 5px;
            line-height: 1.3;
          }
          margin-bottom: 8px;
        }
        .sd-t3-box-left-four {
          align-self: center;
          button {
            font-size: 12px;
            border: none;
            padding: 2px 10px;
            word-break: keep-all;
            img {
              height: 10px;
              margin-left: 5px;
              margin-top: 2px;
            }
          }
        }
      }
      .sd-t3-box-right {
        padding: 10px 20px 20px 20px;
        width: 100%;
        height: 45%;
        align-self: center;
        Iframe {
          // height: 160px;
          // width: 290px;
        }
        img {
          height: 160px;
          width: 290px;
        }
      }
    }
  }
  .sd-template-four {
    height: 720px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .sd-t4-box-left {
      width: 100%;
      height: 50%;
      padding: 25px;
      .sd-t4-box-left-one {
        margin-bottom: 5px;
        h4 {
          font-size: 17px;
          margin-bottom: 0px;
        }
        p {
          font-size: 22px;
          letter-spacing: 1px;
          color: #2f3293;
          margin-bottom: 5px;
          line-height: 1;
        }
      }
      .sd-t4-box-left-three {
        p {
          font-size: 12px;
          line-height: 1.2;
        }
      }
      .sd-t4-box-left-two {
        p {
          font-size: 14px;
          line-height: 1.2;
          margin-bottom: 10px;
          span {
            font-size: 13px !important;
          }
        }
      }
      .sd-t4-box-left-four {
        button {
          font-size: 12px;
          border: none;
          padding: 2px 10px;
          word-break: keep-all;
          img {
            height: 10px;
            margin-left: 5px;
            margin-top: 2px;
          }
        }
      }
    }
    .sd-t4-box-right {
      width: 100%;
      height: 50%;
      .sd-t4-box-right-one {
        padding: 10px;
        img {
          height: 40px;
        }
        button {
          font-size: 12px;
          border: none;
          padding: 2px 10px;
          word-break: keep-all;
          img {
            height: 10px;
            margin-left: 5px;
            margin-top: 2px;
          }
        }
        h3 {
          font-size: 14px;
          margin-bottom: 0px;
        }
      }

      .sd-t4-box-right-four {
        padding: 20px;
        padding: 10px;
        img {
          height: 35px;
        }
        button {
          font-size: 12px;
          padding: 2px 10px;
          word-break: keep-all;
          img {
            height: 11px;
            margin-left: 5px;
          }
        }
        h3 {
          font-size: 14px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .sd-template-five {
    // height: 500px;
    padding: 20px;
    h2 {
      font-size: 36px;
      // margin-bottom: 20px;
    }
    .sd-photo-grid {
      height: 90%;
      div {
        flex-wrap: wrap;
        margin-bottom: 0vh;

        img {
          margin: 5px;
          margin-bottom: 10px;

          height: 41%;
          width: 46.5%;
        }
      }
    }
    .sd-photo-grid-full {
      div {
        width: 90%;
      }
    }
    button {
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 10px;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}

@media only screen and (min-width: 100px) and (max-width: 350px) {
  .sd-template-one {
    .sd-t1-slider {
    }
    .sd-t1-bottom {
      .sd-marquee-text {
        width: 50%;
      }
      div {
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 9px;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sd-template-three {
    height: 500px;
    .sd-t3-inner-div {
      .sd-t3-box-left {
      }
      .sd-t3-box-right {
        align-items: center;
        Iframe {
          // height: 150px;
          // width: 250px;
        }
        img {
          height: 150px;
          width: 250px;
        }
      }
    }
  }
  .sd-template-four {
    margin-bottom: 30px;
    .sd-t4-box-left {
      height: 400px;
    }
    .sd-t4-box-right {
    }
  }
  .sd-template-five {
    // height: 500px;
    padding: 20px;
    h2 {
      font-size: 36px;
      // margin-bottom: 20px;
    }
    .sd-photo-grid {
      height: 90%;
      div {
        flex-wrap: wrap;
        margin-bottom: 0vh;

        img {
          margin: 5px;
          margin-bottom: 1vh;

          height: 41%;
          width: 46%;
        }
      }
    }
    button {
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 10px;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}

@keyframes circleAnimation {
  0% {
    stroke-dashoffset: 75;
  }

  50% {
    stroke-dashoffset: 280;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
