.App {
  text-align: center;
  font-family: "Barlow", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}
.sd-main {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #181818;
  -webkit-overflow-scrolling: touch !important;
}
.grecaptcha-badge {
  display: none !important;
}
.sd-pop-up {
  position: absolute;
  border: none !important;
  outline: none !important;
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.8);

  .inside-pop {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 666px;
    // max-height: 40vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 50px 1px #000;
    padding: 25px;
    position: relative;
    animation: scale 1s;
    text-align: center;
    font-size: 20px;

    .inside-slider {
      width: 100%;
      height: 300px;
      .slick-track {
        width: 100% !important;
      }
    }
    .sd-flex-c {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      .s-button {
        background-color: #f58220;
        color: white;
        padding: 5px 10px;
        font-size: 15px;
        border: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .s-l-left {
          margin-left: 5px;
          height: 11px;
          margin-top: 2px;
        }
        .s-r-right {
          margin-left: 5px;
          height: 11px;
          margin-top: 2px;
        }
      }
    }
    .s-image {
      width: 100%;
    }
    .sd-top-icon {
      height: 100px;
      margin-bottom: 10px;
    }
    .sd-none {
      display: none;
    }
    .display {
      position: absolute;
      top: 0px;
      right: 10px;
      font-weight: 500;
      border: none;
      height: 8px;
      font-size: 18px;
      background-color: transparent;
    }
    .sd-down-icon {
      height: 150px;
    }
    .sd-top-mv {
      padding: 0px 25px;
      margin-top: -20px;
    }
    p {
      color: #181818;
      font-weight: 500;
      padding: 0px 20px;
      line-height: 26px;

      span {
        font-weight: 600;
      }
    }
  }

  .in-scroll {
    height: 500px !important;
    overflow-x: scroll;
    width: auto;
    // width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.sd-pop-up-2 {
  position: absolute;
  border: none !important;
  outline: none !important;
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.8);

  .inside-pop-2 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    // max-height: 40vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 50px 1px #000;
    padding: 25px;
    position: relative;
    animation: scale 1s;
    text-align: center;
    font-size: 20px;
    .i-p-s-images-2 {
      display: flex;
      width: 100%;
      height: 500px;
      justify-content: space-between;
      overflow-x: scroll;
      // justify-content: space-evenly;
      img {
        width: 100%;
      }
      .s-image-inside-2 {
        width: 400px;
      }
    }
    .inside-slider-2 {
      width: 100%;
      height: 300px;
      .slick-track {
        width: 100% !important;
      }
    }
    .sd-flex-c-2 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      .s-button-2 {
        background-color: #f58220;
        color: white;
        padding: 5px 10px;
        font-size: 15px;
        border: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .s-l-left-2 {
          margin-left: 5px;
          height: 11px;
          margin-top: 2px;
        }
        .s-r-right-2 {
          margin-left: 5px;
          height: 11px;
          margin-top: 2px;
        }
      }
    }
    .s-image-2 {
      width: 100%;
    }
    .sd-top-icon-2 {
      height: 100px;
      margin-bottom: 10px;
    }
    .sd-none-2 {
      display: none;
    }
    .display-2 {
      position: absolute;
      top: 2px;
      right: 12px;
      font-weight: 500;
      border: none;
      height: 8px;
      font-size: 18px;
      background-color: transparent;
    }
    .sd-down-icon-2 {
      height: 150px;
    }
    .sd-top-mv-2 {
      padding: 0px 25px;
      margin-top: -20px;
    }
    p {
      color: #181818;
      font-size: 500;
      padding: 0px 20px;
      line-height: 22px;
      font-size: 16px;

      span {
        font-weight: 600;
      }
    }
  }

  .in-scroll-2 {
    height: 500px !important;
    overflow-x: scroll;
    width: auto;
    // width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
button {
  outline: none;
}
.none {
  display: none;
  transform: display 1s;
  animation: reverse 1s;
}

@keyframes scale {
  0% {
    // opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes reverse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.sd-error {
  display: none;
  width: 0;
  height: 0;
}
input {
  text-transform: capitalize;
  &::placeholder {
    text-transform: none;
  }
}

.sd-inner-error {
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media only screen and (max-width: 319px) {
  .sd-main {
    display: none;
  }
  .sd-error {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .sd-inner-error {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    font-size: large;
    flex-direction: column;
    background-color: white;
    img {
      height: 100px;
      width: 100px;
    }
    span {
      font-weight: 6vw;
      color: #181818;
      line-height: 3vh;
      text-align: center;
      font-weight: 600;
    }
  }
}
@media only screen and (min-width: 2640px) {
  .sd-main {
    display: none;
  }
  .sd-error {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .sd-inner-error {
    display: flex;
    height: 100vh;

    justify-content: center;
    align-items: center;
    font-size: xx-large;
    flex-direction: column;
    background-color: white;

    img {
      height: 200px;
      width: 200px;
    }
    span {
      font-weight: 5vw;
      color: #181818;
      line-height: 3vh;
      font-weight: 600;
      text-align: center;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sd-error {
  height: "100vh";
  display: flex;
  flex-direction: column;
  justify-content: center;
}
// @media only screen and (max-width: 1300px) and (min-width: 1000px) {
//   .sd-pop-up {
//     div {
//       padding: 30px;

//       max-width: 50vw;

//       .sd-down-icon {
//         height: 130px;
//       }
//       p {
//         font-size: 18px;
//       }
//       .sd-top-icon {
//         height: 80px;
//       }
//     }
//   }
// }
@media only screen and (min-width: 1500px) {
  .sd-pop-up {
    div {
      max-width: 520px;
      // p {
      //   font-size: 19px;
      // }
      // .sd-down-icon {
      //   height: 120px;
      // }
      // .sd-top-icon {
      //   height: 80px;
      // }
    }
  }
}
@media only screen and (min-width: 650px) and (max-width: 1000px) {
  .sd-pop-up {
    div {
      max-width: 400px;
      p {
        font-size: 19px;
      }
      .sd-down-icon {
        height: 130px;
      }
      .sd-top-icon {
        height: 80px;
      }
    }
  }
  .sd-pop-up-2 {
    .inside-pop-2 {
      .i-p-s-images-2 {
      }
    }

    // div {
    //   max-width: 400px;
    //   p {
    //     font-size: 19px;
    //   }
    //   .sd-down-icon {
    //     height: 130px;
    //   }
    //   .sd-top-icon {
    //     height: 80px;
    //   }
    // }
  }
}
@media only screen and (min-width: 420px) and (max-width: 650px) {
  .sd-pop-up {
    div {
      max-width: 300px;
      p {
        padding: 0px;

        font-size: 15px;
      }
      .sd-top-mv {
        padding: 0px;
      }
      .sd-down-icon {
        height: 110px;
      }
      .sd-top-icon {
        height: 80px;
      }
    }
  }
  .sd-pop-up-2 {
    .inside-pop-2 {
      .i-p-s-images-2 {
        display: flex;
        flex-direction: column;
        .s-image-inside-2 {
          width: 100%;
        }
      }
    }

    .in-scroll-2 {
      overflow-y: scroll;
      width: auto;
      // width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .sd-pop-up {
    div {
      max-width: 280px;
      p {
        font-size: 15px;
        padding: 0px;
      }
      .sd-top-mv {
        padding: 0px;
      }
      .sd-down-icon {
        height: 110px;
      }
      .sd-top-icon {
        height: 80px;
      }
    }
  }
  .sd-pop-up-2 {
    .inside-pop-2 {
      .i-p-s-images-2 {
        display: flex;
        flex-direction: column;
        .s-image-inside-2 {
          width: 100%;
        }
      }
    }

    .in-scroll-2 {
      overflow-y: scroll;
      width: auto;
      // width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.mt-5 {
  margin-top: 5px;
}
