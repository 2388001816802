.sd-favorities {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .seva-div {
    overflow: hidden;
    padding: 18px 18px 0;
    // margin-bottom: 30px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 20px 30px;
    .sd-copy {
      position: absolute;
      top: -60px;
      font-size: 18px;
      right: 47%;
      font-weight: 500;
      background-color: rgb(228, 252, 253);
      padding: 5px 15px;
      border-radius: 4px;
      animation: slow-message 1s;
      border: 1.5px solid #bfbfbf;
    }
    .head-image {
      width: 100%;
      height: 150px;
      border-radius: 4px;
      align-self: center;
      object-fit: contain !important;
    }
    .sd-head-image {
      //   height: 150px;
      width: 60px;
      height: 60px;
      margin: 15px 15px 0px 15px;

      align-self: center;
    }
    .spcl {
      width: 80px;
      height: 50px;
      margin: 25px 25px 0px 25px;
    }
    h4 {
      text-align: center;
      color: #2f3193;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      padding: 15px 0 5px;
    }
    h5 {
      text-align: center;
      letter-spacing: 0.3px;
      font-size: 15px;
      font-weight: 400;
      color: #181818;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      line-height: 20px;
    }
    h6 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 5px 0 16px;
      font-size: 15px;
      font-weight: 600;
      color: #f58220;
      margin-bottom: 0px;
      cursor: pointer;

      img {
        height: 11px;
        margin-left: 5px;
        // margin-top: 2px;
        filter: invert(1);
      }
    }
    .sd-timings {
      background-color: #e5e5e5;
      margin-left: -18px;
      width: 350px;
      padding: 0px 20px;
      display: flex;
      justify-content: center;
      div {
        img {
          height: 23px;
          width: 30px;
          cursor: pointer;

          padding: 4px;
        }
        .border-l {
          border-left: 1px solid grey;
          padding-left: 10px;
        }
        .padding-r {
          padding-right: 10px;
        }
      }
      h3 {
        text-align: center;
        // margin-left: -18px;
        // margin-right: -18px !important;
        text-align: center;
        padding: 12px 0;
        font-weight: 500;
        opacity: 0.7;
        color: #181818;

        font-size: 14px;
        background-color: #e5e5e5;
        margin-bottom: 0px;
        i {
          margin-right: 8px;
        }
      }
    }

    div {
      display: flex;
      padding: 12px 0;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
        color: #181818;

        img {
          height: 13px;
          margin-right: 3px;
        }
      }
      button {
        color: white;
        background-color: #f58220;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        border-radius: 3px;
        justify-content: center;
        font-size: 14px;
        padding: 6px 13px;
        white-space: nowrap;

        font-weight: 600;
        letter-spacing: 0.3px;
        .imgs {
          height: 11px;
          margin-left: 5px;
        }
      }
    }
  }
}
.sd-seva-booking {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .seva-div-booking {
    overflow: hidden;
    padding: 18px 18px 0;
    // margin-bottom: 30px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    width: 285px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 20px 30px 0px;
    .sd-copy-booking {
      position: absolute;
      top: -60px;
      font-size: 18px;
      right: 47%;
      font-weight: 500;
      background-color: rgb(228, 252, 253);
      padding: 5px 15px;
      border-radius: 4px;
      animation: slow-message 1s;
      border: 1.5px solid #bfbfbf;
    }
    .head-image-booking {
      width: 100%;
      height: 130px;
      border-radius: 4px;
      align-self: center;
    }
    .sd-head-image-booking {
      //   height: 150px;
      width: 60px;
      height: 60px;
      margin: 15px 15px 0px 15px;

      align-self: center;
    }
    .spcl-booking {
      width: 80px;
      height: 50px;
      margin: 25px 25px 0px 25px;
    }
    h4 {
      text-align: center;
      color: #2f3193;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      padding: 15px 0 5px;
    }
    h5 {
      text-align: center;
      letter-spacing: 0.3px;
      font-size: 15px;
      font-weight: 400;
      color: #181818;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      line-height: 20px;
    }
    h6 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 5px 0 5px;
      font-size: 15px;
      font-weight: 600;
      color: #f58220;
      margin-bottom: 0px;
      cursor: pointer;

      img {
        height: 11px;
        margin-left: 5px;
        // margin-top: 2px;
        filter: invert(1);
      }
    }
    .sd-timings-booking {
      background-color: #e5e5e5;
      margin-left: -18px;
      width: 285px;
      padding: 0px 20px;
      display: flex;
      justify-content: center;
      div {
        img {
          height: 23px;
          width: 28px;
          cursor: pointer;

          padding: 4px;
        }
        .border-l-booking {
          border-left: 1px solid grey;
          padding-left: 10px;
        }
        .padding-r-booking {
          padding-right: 10px;
        }
      }
      h3 {
        display: flex;
        align-items: center;
        // margin-left: -18px;
        // margin-right: -18px !important;
        text-align: center;
        padding: 12px 0;
        font-weight: 600;
        opacity: 0.7;
        color: #181818;

        font-size: 14px;
        background-color: #e5e5e5;
        margin-bottom: 0px;
        img {
          height: 15px;
          margin-right: 5px;
        }
      }
    }

    div {
      display: flex;
      padding: 12px 0;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0px;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
        color: #181818;
        display: flex;
        align-items: center;
        img {
          height: 14px;
        }
      }
      button {
        color: white;
        background-color: #f58220;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        border-radius: 3px;
        justify-content: center;
        font-size: 14px;
        padding: 6px 13px;
        white-space: nowrap;

        font-weight: 600;
        letter-spacing: 0.3px;
        .imgs-booking {
          height: 11px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1827px) {
  .seva-div {
    width: 400px;
    .sd-timings {
      width: 400px;
    }
  }
  .seva-div-booking {
    width: 400px;
    .sd-timings-booking {
      width: 400px;
    }
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1827px) {
  .seva-div {
    width: 400px;
    .sd-timings {
      width: 400px;

      // width: 28.8vw;
    }
  }
  .seva-div-booking {
    width: 400px;
    .sd-timings-booking {
      width: 400px;

      // width: 28.8vw;
    }
  }
}
@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .seva-div {
    width: 350px;
    .sd-timings {
      width: 350px;
      // margin-right: -6%;
    }
  }
  .seva-div-booking {
    width: 310px !important;
    .sd-timings-booking {
      width: 310px !important;
      // margin-right: -6%;
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 650px) {
  .seva-div {
    width: 440px;
    margin-bottom: 20px;
    .head-image {
      height: 180px;
    }
    .sd-timings {
      width: 440px;
      // width: 118%;
      // width: 118%;
      // margin-left: -8%;
      h3 {
        font-size: 13px;
      }
    }
  }
  .seva-div-booking {
    width: 440px;
    margin-bottom: 20px;
    .head-image-booking {
      height: 180px;
    }
    .sd-timings-booking {
      width: 440px;
      // width: 118%;
      // width: 118%;
      // margin-left: -8%;
      h3 {
        font-size: 13px;
      }
    }
  }
}
@media only screen and (min-width: 420px) and (max-width: 650px) {
  .seva-div {
    .head-image {
      height: 120px;
    }
    width: 320px;
    margin-bottom: 30px;
    .sd-timings {
      width: 320px;
      // width: 110%;
      // margin-left: -5%;
    }
  }
  .seva-div-booking {
    .head-image-booking {
      height: 40% !important;
    }
    width: 100% !important;
    margin-bottom: 30px;
    .sd-timings-booking {
      width: 110% !important;
      // width: 110%;
      margin-left: -5%;
    }
  }
}
@media only screen and (max-width: 420px) {
  .seva-div {
    width: 270px;
    .head-image {
      height: 120px;
    }
    .sd-timings {
      width: 270px;
    }
  }
  .seva-div-booking {
    margin-right: 0px !important;
    width: 280px;
    .head-image-booking {
      height: 120px;
    }
    .sd-timings-booking {
      width: 280px;
    }
  }
}
