.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__day--excluded {
  background-color: rgb(117, 117, 117);
  border-radius: 0.3rem;
  color: white !important;
}

.react-datepicker__day--excluded:hover {
  background-color: rgb(117, 117, 117);
}

.react-datepicker__day--highlighted-custom-1 {
  background-color: rgb(0, 207, 0);
  border-radius: 0.3rem;
  color: white !important;
}

.react-datepicker__day--highlighted-custom-1:hover {
  background-color: rgb(139, 206, 39);
}

.react-datepicker__day--highlighted-custom-2 {
  background-color: orange;
  border-radius: 0.3rem;
  color: white !important;
}

.react-datepicker__day--highlighted-custom-3 {
  background-color: rgb(201, 0, 0);
  border-radius: 0.3rem;
  color: white !important;
}

.react-datepicker__day--highlighted-custom-3:hover {
  background-color: rgb(201, 0, 0);
  cursor: not-allowed;
}

.react-datepicker__day--highlighted-custom-2:hover {
  background-color: orangered;
}

.react-datepicker__day--selected {
  background-color: #2f3193 !important;
  color: white !important;
}

.monthYearSelectContainer {
  font-size: 15px !important;
  padding: 0px 60px 0px 10px !important;
  background-color: white !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
}

// .react-datepicker__day {
//   background-color: black;
//   color: white !important;
//   border-radius: 0.3rem;
// }

// .react-datepicker__day--disabled {
//   background-color: white !important;
//   color: #ccc !important;
// }
