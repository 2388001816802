.sd-footer-main {
  background-color: #efefef;
  width: 100% !important;
  font-family: "Barlow", sans-serif;

  .sd-footer-top {
    background-color: #efefef;
    height: 180px;
    background-color: #2f3293;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .sd-footer-top-left {
      width: 50%;
      height: 60%;
      align-self: center;
      padding-left: 8.5%;
      padding-right: 6.5%;
      border-right: 2px solid #6263a8;
      p {
        height: 50%;
        margin: 0px;
        color: white;
        font-size: 23px;
        font-weight: 400;
        display: flex;
        justify-content: space-evenly;
      }
      div {
        height: 50%;
        display: flex;
        justify-content: center;
        img {
          height: 45px;
        }
      }
    }
    .sd-footer-top-right {
      width: 50%;
      height: 60%;
      align-self: center;

      padding-left: 30px;
      padding-right: 30px;
      p {
        height: 50%;
        margin: 0px;
        color: white;
        font-size: 23px;
        font-weight: 400;
        display: flex;
        justify-content: space-evenly;
      }

      .sd-sub {
        display: flex;
        // justify-content: space-evenly;
        flex-direction: column;
        height: 75%;
        align-items: center;

        .subscribe {
          background-color: #d8d8d8 !important;
          border-radius: 4px;
          height: 42px;
          display: flex;
          justify-content: space-around;
          width: 80%;
          position: relative;

          h4 {
            color: rgba(255, 0, 0, 0.753);
            font-size: 16px;
            font-weight: 400;
            margin-top: 5px;
            position: absolute;
            bottom: -20px;
            right: 0px;
          }
          input {
            height: 100%;
            outline: none;
            border: none;
            width: 75%;
            background-color: #d8d8d8 !important;
            &::placeholder {
              font-size: 19px;
              font-weight: 400;
              color: black;
            }
          }
          img {
            width: 22px;
            align-self: center;
          }
        }
        .sd-red {
          border: 2px solid red;
        }
        .sd-orange {
          border: 2px solid #f58220;
        }
      }
    }
  }
  .sd-footer-middle {
    background-color: #efefef !important;
    height: 480px;
    width: 100%;
    .sd-footer-middle-f {
      background-color: #efefef !important;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .sd-container-r {
        background-color: #efefef !important;
        padding: 40px 60px 20px 60px;
        padding: 0px 60px 60px 60px;
        .row {
          display: flex;
          justify-content: space-between;
          text-align: initial;
          background-color: #efefef;
          margin: 0px;
          .sd-list-body {
            // width: 16.66%;
            padding: 5px;
            h5 {
              font-size: 18px;
              color: #2f3193;
              margin-bottom: 13px !important;

              font-weight: 600;
            }
            ul {
              li {
                padding: 2.5px 1px;
                a {
                  font-size: 15px;
                  font-weight: 400;
                  color: #181818;
                  padding-bottom: 3px;
                  text-decoration: none;
                  cursor: pointer;
                  &:hover {
                    color: #181818;
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .w-16 {
            width: 16%;
          }
          .w-18 {
            width: 19%;
          }
          .w-15 {
            width: 14%;
          }
        }
      }
      .sd-additional-footer {
        border-top: 1px solid #c0c3c7;
        // border-bottom: 1px solid #c0c3c7;
        border-bottom: 1px solid #c0c3c7;

        width: 93%;
        height: 19%;
        display: flex;
        justify-content: space-evenly;
        align-self: center;
        align-items: center;
        .left {
          width: 40%;
          height: 70%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            margin: 0px;
            margin-left: 0px;
            font-size: 15px;
            color: #181818;
            i {
              margin-right: 13px;
            }
            img {
              height: 15px;
              margin-right: 10px;
            }
          }
        }
        .middle {
          width: 35%;
          height: 70%;
          padding-left: 30px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            margin: 0px;
            font-size: 15px;
            color: #181818;
            img {
              height: 13px;
              margin-right: 10px;
            }
            .sep {
              height: 9px;
            }
          }
          // img {
          //   height: 20px;
          //   margin-right: 10px;
          // }
        }

        .right {
          width: 25%;
          height: 70%;
          text-align: left;
          display: flex;
          padding: 19px;
          flex-direction: column;
          justify-content: center;
          padding-left: 30px;
          p {
            margin: 0px;
            font-size: 15px;
            color: #181818;
            img {
              height: 13px;
              margin-right: 10px;
            }
          }
        }
        .sd-border-g {
          border-right: 1px solid #dcdcdc;
          border-left: 1px solid #dcdcdc;
        }
        .grey {
          color: #7f7f7f;
        }
      }
      .sd-visitors {
        position: absolute;
        width: 93.5%;
        align-self: center;
        padding-top: 40px;
        display: flex;
        bottom: -32px;
        justify-content: center;
        .left {
          padding-right: 32vw;
          margin-right: 3vw;

          p {
            font-weight: 600;

            color: #181818;
            span {
              background-color: #5a5a5a;
              color: white;
              margin-left: 8px;
              padding: 2px 5px;
              font-weight: 800;
              border-radius: 4px;
              letter-spacing: 0.7px;
            }
          }
        }
        .right {
          margin-left: -3vw;
          p {
            font-weight: 600;

            color: #181818;
            span {
              margin-left: 8px;
              padding: 2px 5px;
              background-color: #5a5a5a;
              color: white;
              font-weight: 700;
              border-radius: 4px;
              letter-spacing: 0.7px;
            }
          }
        }
      }
    }
  }
  .sd-footer-bottom {
    height: 120px;
    background-color: #efefef;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      background-color: #efefef;
    }
  }
  .sd-footer-tag {
    height: 60px;
    padding: 0px 60px;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-around;
    .left {
      width: 20%;
      display: flex;
      text-align: left;
      img {
        height: 30px;
        width: 30px;

        padding: 5px;
        cursor: pointer;
      }
      .special {
        background-color: white;
        color: #2f3193;
        padding: 0vw;
        font-size: 18px;
      }
    }
    .middle {
      width: 60%;
      height: 25%;
      justify-content: center;
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 400;
        color: #181818;

        cursor: pointer;
      }
      .border-r {
        border-right: 1px solid #c0c0c0;
      }
      .color {
        color: #2f3293;
      }
    }
    .right {
      width: 20%;
      text-align: right;
      font-size: 13px;
      cursor: pointer;
      span {
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }
}
.callout {
  position: fixed;
  bottom: 35px;
  right: 20px;
  z-index: 1;
  margin-left: 20px;
  max-width: 300px;
  width: 250px;
  animation: slide 1s;
  box-shadow: 1px 1px 200px 61px;
}

.callout-header {
  padding: 25px 15px;
  background: #2f3293;
  font-size: 30px;
  color: white;
}

.callout-container {
  padding: 20px;
  background-color: #ccc;
  color: black;
  button {
    background-color: #2f3293;
    color: white;
    padding: 6px 20px;
    border-radius: 3px;
    font-size: 16px;
    border: none;
  }
  p {
    font-size: 20px;
    color: #181818;
  }
}

.closebtn {
  position: absolute;
  top: 5px;
  right: 15px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.closebtn:hover {
  color: lightgrey;
}
@keyframes slide {
  0% {
    // opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@media only screen and (min-width: 1500px) {
  .sd-footer-main {
    .sd-footer-top {
    }
    .sd-footer-bottom {
      height: 150px;
      img {
        height: 100%;
      }
    }
    .sd-footer-middle {
    }
    .sd-footer-tag {
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-footer-main {
    height: 760px;
    padding-top: 0px;
    .sd-footer-top {
      height: 180px;
      .sd-footer-top-left {
        p {
          font-size: 23px;
        }
        div {
          img {
            width: 45%;
          }
        }
      }
      .sd-footer-top-right {
        p {
          font-size: 23px;
        }
        .sd-sub {
          .subscribe {
            input {
              width: 70%;
            }
            h4 {
              font-size: 14px;
            }
            img {
            }
          }
        }
      }
    }
    .sd-footer-middle {
      height: 520px;
      .sd-footer-middle-f {
        .sd-container-r {
          padding: 30px;

          .row {
            .sd-list-body {
              // width: 20%;
              h5 {
                font-size: 18px;
              }
              ul {
                li {
                  width: 80%;
                  a {
                    font-size: 14px;
                    color: black;
                    padding-bottom: 3px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 100px;
          .left {
            width: 38%;
            p {
              font-size: 11.5px;

              img {
                height: 13px;
              }
            }
          }
          .middle {
            padding: 10px;
            padding-left: 15px;
            p {
              font-size: 11.5px;
              img {
                height: 12px;
              }
              .sep {
                height: 10px;
              }
            }
          }
          .right {
            width: 28%;
            p {
              font-size: 11px;

              img {
                height: 12px;
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 100px;
    }
    .sd-footer-tag {
      // height: ;
      padding-left: 50px;
      padding-right: 50px;

      .left {
        img {
          height: 25px;
          width: 25px;
          padding: 4px;
        }
      }
      .middle {
        p {
          font-size: 12px;
        }
      }
      .right {
        img {
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 650px) {
  .sd-footer-main {
    height: 970px;
    .sd-footer-top {
      height: 150px;
      .sd-footer-top-left {
        p {
          font-size: 22px;
          align-items: center;
        }
        div {
          img {
            // height: 75%;
            width: 55%;
          }
        }
      }
      .sd-footer-top-right {
        p {
          font-size: 22px;
          align-items: center;
        }
        .sd-sub {
          .subscribe {
            input {
              width: 65%;
              &::placeholder {
                font-size: 14px;
              }
            }
            h4 {
              font-size: 12px;
            }
            img {
              width: 9%;
            }
          }
        }
      }
    }
    .sd-footer-middle {
      height: 820px;
      padding-top: 20px;

      .sd-footer-middle-f {
        height: 800px;
        justify-content: flex-start;

        .sd-container-r {
          width: 100%;
          background-color: #efefef !important;
          padding: 20px 20px 20px 20px;
          // padding-top: 20px;
          padding-bottom: 0px;
          height: 75%;
          display: flex;
          flex-wrap: wrap;
          .row {
            justify-content: flex-start !important;
            flex-wrap: wrap;
            .sd-list-body {
              width: 33.33%;
              padding-left: 40px;
              padding-bottom: 10px;
              h5 {
                font-size: 17px;
              }
              ul {
                li {
                  a {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 170px;
          // display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // flex-direction: column;
          .left {
            width: 50%;
            border-bottom: 2px solid #dcdcdc;
            border-right: none;
            padding-left: 20px;
            height: 50%;

            p {
              font-size: 13px;
              img {
                height: 15px;
                margin-right: 5px;
              }
              i {
                margin-right: 8px;
              }
            }
          }
          .middle {
            height: 50%;

            width: 50%;
            padding: 10px;
            padding-left: 30px;
            border-right: none;

            border-left: none;

            p {
              font-size: 12px;

              img {
                height: 15px;
              }
              .sep {
                height: 10px;
              }
            }
          }
          .right {
            height: 50%;

            width: 50%;
            padding-left: 30px;

            p {
              font-size: 12px;

              img {
                height: 12px;
              }
            }
          }
        }
        .sd-visitors {
          .left {
            padding-right: 32vw;
            p {
              font-weight: 600;

              color: #181818;
              span {
                background-color: #5a5a5a;
                color: white;
                margin-left: 8px;
                padding: 2px 5px;
                font-weight: 800;
                border-radius: 4px;
                letter-spacing: 0.7px;
              }
            }
          }
          .right {
            margin-left: -4vw;
            p {
              font-weight: 600;

              color: #181818;
              span {
                margin-left: 8px;
                padding: 2px 5px;
                background-color: #5a5a5a;
                color: white;
                font-weight: 700;
                border-radius: 4px;
                letter-spacing: 0.7px;
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 80px;
    }
    .sd-footer-tag {
      height: 50px;
      padding: 10px;
      .left {
        width: 15%;
        img {
          height: 20px;
          width: 20px;
          padding: 2px;
        }
      }
      .middle {
        width: 70%;
        p {
          font-size: 10px;
        }
      }
      .right {
        width: 15%;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 650px) and (min-width: 350px) {
  .sd-footer-main {
    height: 360px;
    .sd-footer-top {
      height: 280px;
      display: flex;
      flex-direction: column;
      .sd-footer-top-left {
        width: 90%;
        height: 50%;
        border-bottom: 1.5px solid rgb(179, 176, 176);
        border-right: none;
        padding: 0px 15px;
        p {
          font-size: 22px;
          align-items: center;
        }
        div {
          padding: 5px;
          img {
            height: 70%;
            // width: 50%;
            // width: 60%;
          }
        }
      }
      .sd-footer-top-right {
        margin-top: -15px;
        width: 100%;
        height: 50%;
        padding: 0px 24px;
        p {
          height: 50%;
          font-size: 22px;
          align-items: center;
        }
        div {
          height: 50%;
          .subscribe {
            width: 315px;
            height: 60%;
            input {
              width: 60%;
              font-size: 15px;
              &::placeholder {
                font-size: 15px;
              }
            }
            img {
              width: 7.5%;
            }
          }
        }
      }
    }
    .sd-footer-middle {
      height: 1150px;
      display: none;
      .sd-footer-middle-f {
        justify-content: flex-start;
        .sd-container-r {
          width: 100%;
          background-color: #efefef !important;
          padding: 20px;
          padding-top: 40px;
          padding-bottom: 60px;
          height: 75%;
          .row {
            justify-content: flex-start;
            flex-wrap: wrap;
            .sd-list-body {
              width: 50%;
              padding-left: 25px;
              padding-bottom: 20px;
              h5 {
                font-size: 17px;
              }
              ul {
                li {
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 125px;
          // display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // flex-direction: column;
          .left {
            width: 100%;
            border-bottom: 2px solid #dcdcdc;
            border-right: none;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;

            p {
              font-size: 11px;
              img {
                height: 18px;
              }
              i {
                margin-right: 16px;
              }
            }
          }
          .middle {
            width: 100%;
            padding: 10px;
            padding-left: 15px;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #dcdcdc;
            justify-content: space-evenly;

            p {
              font-size: 12px;
              img {
                height: 18px;
              }
              .sep {
                height: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-top: 2px solid #dcdcdc;
            border-bottom: 2px solid #dcdcdc;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;
            p {
              font-size: 12px;

              img {
                height: 16px;
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 40px;
      display: none;
    }
    .sd-footer-tag {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100px;
      width: 100%;
      padding: 0px;
      .left {
        justify-content: center;
        margin-left: 0px;

        width: 100%;
        p {
          font-size: 10px;
          img {
            // font-size: 10px;
            height: 20px;
            width: 20px;
            // padding: 10px;
            // font-size: 10px;
          }
        }

        img {
          height: 30px;
          width: 30px;
        }
      }
      .middle {
        width: 100%;
        flex-wrap: wrap;
        p {
          font-size: 10px;
        }
      }
      .right {
        margin-left: 0px;
        text-align: center;
        width: 100%;
        font-size: 10px;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 350px) {
  .sd-footer-main {
    height: 400px;
    .sd-footer-top {
      height: 280px;
      display: flex;
      flex-direction: column;
      .sd-footer-top-left {
        width: 80%;
        height: 50%;
        border-bottom: 1.5px solid rgb(179, 176, 176);
        border-right: none;
        padding-left: 20px;
        padding-right: 20px;
        p {
          font-size: 22px;
          align-items: center;
        }
        div {
          padding: 5px;
          img {
            margin-left: 0px;
            // height: 57%;
            // width: 50%;
            width: 60%;
            height: 35px;
          }
        }
      }
      .sd-footer-top-right {
        margin-top: -15px;
        width: 100%;
        height: 50%;
        padding-left: 20px;
        padding-right: 20px;
        p {
          height: 50%;
          font-size: 22px;
          align-items: center;
        }
        div {
          height: 50%;
          .subscribe {
            width: 90%;
            height: 50%;
            input {
              width: 60%;
              font-size: 15px;
              &::placeholder {
                font-size: 15px;
              }
            }
            img {
              width: 7.5%;
            }
          }
        }
      }
    }
    .sd-footer-middle {
      display: none;
      height: 1200px;
      .sd-footer-middle-f {
        justify-content: flex-start;
        .sd-container-r {
          width: 100%;
          background-color: #efefef !important;
          padding: 20px;
          padding-top: 40px;
          padding-bottom: 60px;
          height: 75%;
          .row {
            justify-content: flex-start;
            flex-wrap: wrap;
            .sd-list-body {
              width: 50%;
              padding-left: 25px;
              padding-bottom: 10px;
              h5 {
                font-size: 17px;
              }
              ul {
                li {
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 125px;
          // display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // flex-direction: column;
          .left {
            width: 100%;
            border-bottom: 2px solid #dcdcdc;
            border-right: none;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;

            p {
              font-size: 11px;
              img {
                height: 18px;
              }
              i {
                margin-right: 16px;
              }
            }
          }
          .middle {
            width: 100%;
            padding: 10px;
            padding-left: 15px;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #dcdcdc;
            justify-content: space-evenly;

            p {
              font-size: 12px;
              img {
                height: 18px;
              }
              .sep {
                height: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-top: 2px solid #dcdcdc;
            border-bottom: 2px solid #dcdcdc;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;
            p {
              font-size: 12px;

              img {
                height: 16px;
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 40px;
      display: none;
    }
    .sd-footer-tag {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 110px;
      width: 100%;
      padding: 0px;
      .left {
        justify-content: center;
        margin-left: 0px;

        width: 100%;
        p {
          font-size: 9.5px;
          img {
            // font-size: 10px;
            height: 20px;
            width: 20px;
            // padding: 10px;
            // font-size: 10px;
          }
        }

        img {
          height: 30px;
          width: 30px;
        }
      }
      .middle {
        width: 100%;
        flex-wrap: wrap;
        p {
          font-size: 9.5px;
        }
      }
      .right {
        margin-left: 0px;
        text-align: center;
        width: 100%;
        font-size: 9.5px;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
